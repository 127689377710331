import React from "react";
import Markdown from "markdown-to-jsx";
import getVideoId from 'get-video-id';

const VideoEmbed = ({ children }) => {
  const getVideo = getVideoId(children)
  let iGDrive = ""
  let iYoutube = ""
  let iVimeo = ""
  let iTikTok = ""
  let vMp4 = ""
  let vWebm = ""
  let aOgg = ''
  let aMp3 = ''
  
  // Google Drive Files: pdf, mp4, mov, etc
  if (children.indexOf("drive.google.com/file/d/") !== -1) {
    let count = children.match(/\bhttps:\/\/drive.google.com\/file\/d\S+/g);
    
    if (count !== null) {
      for (let i = 0; i < count.length; i++) {
        const drive_id = count[i].split('/')[5]
        let urlGDrive = "https://drive.google.com/file/d/"+drive_id+"/preview"
        iGDrive += '<p><iframe width="320" height="180" src="'+urlGDrive+'" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></p>'
      }

      return <Markdown>{iGDrive}</Markdown>;
    }
  }

  if (!children) return null;

  // service youtube
  if (getVideo.service === 'youtube') {
    let count = children.match(/\byoutube.com\S+|youtu.be\S+|y2u.be\S+/g);
    
    if (count !== null) {
      for (let i = 0; i < count.length; i++) {
        const {id} = getVideoId(count[i])
        
        let urlYoutube = "https://www.youtube.com/embed/"+id
        iYoutube += '<p><iframe width="320" height="180" src="'+urlYoutube+'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>'
      }

      return <Markdown>{iYoutube}</Markdown>;
    }
  }

  // service vimeo
  if (getVideo.service === 'vimeo') {
    let count = children.match(/\bhttps:\/\/www.vimeo.com\S+|https:\/\/vimeo.com\S+/g);
    
    if (count !== null) {
      for (let i = 0; i < count.length; i++) {
        const {id} = getVideoId(count[i])
        
        let urlVimeo = "https://player.vimeo.com/video/"+id
        iVimeo += '<p><iframe width="320" height="180" src="'+urlVimeo+'" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></p>'
      }

      return <Markdown>{iVimeo}</Markdown>;
    }
  }

  // service tiktok
  if (getVideo.service === 'tiktok') {
    let count = children.match(/\bhttps:\/\/www.tiktok.com\S+|https:\/\/tiktok.com\S+/g);
    
    if (count !== null) {
      for (let i = 0; i < count.length; i++) {
        const {id} = getVideoId(count[i])
        
        let urlTikTop = "https://www.tiktok.com/embed/v2/"+id
        iTikTok += '<p><iframe width="320" height="750" src="'+urlTikTop+'" frameborder="0"></iframe></p>'
      }

      return <Markdown>{iTikTok}</Markdown>;
    }
  }

  // mp4 video
  if (children.indexOf(".mp4") !== -1) {
    let matches = children.match(/\bhttps?:\/\/\S+/gi);
    
    if (matches !== null) {
      for (let i = 0; i < matches.length; i++) {
        let video = '';
        video += '<p><video width="320" height="auto" controls>'
        video += '<source src="'+ matches[i] +'" type="video/mp4">'
        video += 'Your browser does not support the video tag.'
        video += '</video></p>'

        vMp4 += video
      }
    
      return <Markdown>{vMp4}</Markdown>;
    }
  }

  // webm video
  if (children.indexOf(".webm") !== -1) {
    let matches = children.match(/\bhttps?:\/\/\S+/gi);
    
    if (matches !== null) {
      for (let i = 0; i < matches.length; i++) {
        let video = '';
        video += '<p><video width="320" height="auto" controls>'
        video += '<source src="'+ matches[i] +'" type="video/webm">'
        video += 'Your browser does not support the video tag.'
        video += '</video></p>'

        vWebm += video
      }
      
      return <Markdown>{vWebm}</Markdown>;
    }
  }

  // mp3 audio
  if (children.indexOf(".mp3") !== -1) {
    let matches = children.match(/\bhttps?:\/\/\S+/gi);
    
    if (matches !== null) {
      for (let i = 0; i < matches.length; i++) {
        let audio = '';
        audio += '<p><audio controls>'
        audio += '<source src="'+ matches[i] +'" type="audio/mpeg">'
        audio += 'Your browser does not support the audio tag.'
        audio += '</audio></p>'

        aMp3 += audio
      }
      
      return <Markdown>{aMp3}</Markdown>;
    }
  }

  // ogg audio
  if (children.indexOf(".ogg") !== -1) {
    let matches = children.match(/\bhttps?:\/\/\S+/gi);
    
    if (matches !== null) {
      for (let i = 0; i < matches.length; i++) {
        let audio = '';
        audio += '<p><audio controls>'
        audio += '<source src="'+ matches[i] +'" type="audio/ogg">'
        audio += 'Your browser does not support the audio tag.'
        audio += '</audio></p>'

        aOgg += audio
      }
      
      return <Markdown>{aOgg}</Markdown>;
    }
  }

  return <Markdown>&nbsp;</Markdown>
};

export default VideoEmbed;